import * as $ from "jquery";
import {LogChart} from "./LogChart";
import {BackupLog} from "./BackupLog";
import {BackupSummary} from "./BackupSummary";
import WebUser from "./WebUser";
import {CompleteSummary} from "./CompleteSummary";
import ServerStats from "./ServerStats";
import BackupNotification from "./BackupNotification";
import BackupRestore from "./BackupRestore";

$(() => {

    $(document).on('click', '.delete', function(e) {
        $(this).parent().hide();
    });

    $('.dropdown').on('click', function(e) {
        $(this).toggleClass('is-active');
    });

    let logChartTransferBar = $('#log_chart_bar');
    let logChartTransferPie = $('#log_chart_pie');
    let userChartSummary = $('#user_summary_chart');
    let dashboardOverviewPie = $('#overview_chart_pie');
    let dashboardTimeOverviewPie = $('#time_overview_chart_pie');
    let dashboardServerStatsPie = $('#overview_server_pie');
    let dashboardServerStatsLine = $('#overview_server_line');

    if(dashboardServerStatsPie.length > 0) {
        let server = new ServerStats();
        server.fetch().then(() => {
            LogChart.serverStatsLatest(server, dashboardServerStatsPie);
        }).catch((exception: Error) => {
             dashboardServerStatsPie.replaceWith('<div class="notification is-danger">' + exception.message + '</div>');
        });
    }

    if(dashboardServerStatsLine.length > 0) {
        let server = new ServerStats();
        server.fetchWeek().then(() => {
            LogChart.serverStatsSummary(server, dashboardServerStatsLine);
        }).catch((exception: Error) => {
            dashboardServerStatsLine.replaceWith('<div class="notification is-danger">' + exception.message + '</div>');
        });
    }

    if(dashboardOverviewPie.length > 0 && dashboardTimeOverviewPie.length > 0) {
        let summary = new CompleteSummary();
        summary.fetch().then(() => {
            LogChart.backupStatusChart(summary, dashboardOverviewPie);
            LogChart.backupTimeOverviewChart(summary, dashboardTimeOverviewPie);
        }).catch((exception: Error) => {
            dashboardOverviewPie.replaceWith('<div class="notification is-danger">' + exception.message + '</div>');
        })
    }


    if(logChartTransferBar.length > 0 && logChartTransferPie.length > 0) {
        let user = logChartTransferBar.data('user');
        let log = new BackupLog(logChartTransferBar.data('id'), user);
        log.fetch().then(() => {
            LogChart.transferPieChart(log, logChartTransferPie);
            LogChart.transferBarChart(log, logChartTransferBar);
        }).catch((exception: Error) => {
            logChartTransferPie.replaceWith('<div class="notification is-danger">' + exception.message + '</div>')
            logChartTransferBar.replaceWith('<div class="notification is-danger">' + exception.message + '</div>')
        });
    }

    if(userChartSummary.length > 0) {
        let user = userChartSummary.data('user');
        let summary = new BackupSummary(user);
        Promise.all([summary.fetch()]).then(() => {
            LogChart.summaryChart(summary, userChartSummary);
        }).catch((exception: Error) => {
            userChartSummary.replaceWith('<div class="notification is-danger">' + exception.message + '</div>');
        });
    }

    $('#profile_update').on('submit', function(e) {
        e.preventDefault();
        let user = WebUser.fromProfileForm($(this));
        user.update($('#message_container'), $(this));
    });

    $('#password_patch').on('submit', function(e) {
        e.preventDefault();
        let user = WebUser.fromPasswordForm($(this));
        user.patchPassword($('#message_container'), $(this));
    });

    $('#notification_action').on('submit', function(e) {
        e.preventDefault();
        let notification = BackupNotification.fromForm($(this));
        notification.update($('#message_container'), $(this));
    });

    $('.backup_restore').on('click', function(e) {

        let restoreJob = BackupRestore.fromLogView($(this));

        restoreJob.create($('#message_container'));

    });

});
