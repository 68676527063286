import { Chart }from 'chart.js';
import { BackupLog } from "./BackupLog";
import {BackupSummary} from "./BackupSummary";
import {CompleteSummary} from "./CompleteSummary";
import ServerStats from "./ServerStats";
import {draw, generate} from 'patternomaly'

const chartColors = {
    red: 'rgb(255,32,54)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)'
};


export class LogChart {

    private static globalOptions = {
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        elements: {
            line: {
                tension: 0
            }
        },
        responsive: true
    };

    public static summaryChart(summary: BackupSummary, container: JQuery): Chart
    {
        return new Chart(container, {
            type: 'pie',
            data: {
                labels: summary.getStatusLabelArray(),
                datasets: [{
                    data: summary.getStatusArray(),
                    backgroundColor: [
                        chartColors.green,
                        draw('diagonal', chartColors.green),
                        chartColors.red,
                        draw('diagonal', chartColors.red),
                        chartColors.grey
                    ],
                }],
            },
            options: {
                responsive: true,
                legend: {
                    position: 'right'
                }
            }
        });
    }

    public static transferPieChart(log: BackupLog, container: JQuery): Chart
    {
        return new Chart(container, {
            type: 'pie',
            data: log.getSummaryData(),
            options: {
                responsive: true,
                legend: {
                    position: 'right'
                }
            }
        });
    }

    public static transferBarChart(log: BackupLog, container: JQuery): Chart
    {
        return new Chart(container, {
            type: 'bar',
            data: {
                labels: [
                    'Neue Dateien',
                    'Neue Verzeichnisse',
                    'Neue Links',
                    'Gelöschte Dateien'
                ],
                datasets: [{
                    data: [
                        log.createdRegularFiles,
                        log.createdDirectories,
                        log.createdLinks,
                        log.deletedFiles
                    ],
                    backgroundColor: [
                        '#2baab1',
                        '#36cad2',
                        '#3be5ec',
                        '#195960'
                    ],
                    borderColor: '#30bac2',
                }]
            },
            options: this.globalOptions
        });
    }

    public static backupStatusChart(summary: CompleteSummary, container: JQuery): Chart
    {
        return new Chart(container, {
            type: 'pie',
            data: {
                labels: summary.getStatusLabels(),
                datasets: [{
                    data: summary.getStatusValues(),
                    backgroundColor: [
                        chartColors.green,
                        draw('diagonal', chartColors.green),
                        chartColors.red,
                        draw('diagonal', chartColors.red),
                        chartColors.grey
                    ],
                }]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'right'
                }
            }
        });
    }

    public static backupTimeOverviewChart(summary: CompleteSummary, container: JQuery): Chart
    {
        return new Chart(container, {
            type: 'pie',
            data: {
                labels: summary.getTimeOverviewLabels(),
                datasets: [{
                    data: summary.getTimeOverviewValues(),
                    backgroundColor: [
                        chartColors.green,
                        draw('diagonal', chartColors.green),
                        chartColors.red,
                        draw('diagonal', chartColors.red),
                        chartColors.grey
                    ],
                }]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'right'
                }
            }
        });
    }

    public static serverStatsLatest(server: ServerStats, container: JQuery): Chart
    {
        return new Chart(container, {
            type: 'pie',
            data: {
                labels: server.getLabelsArray(),
                datasets: [{
                    data: server.getDataArray(),
                    backgroundColor: [
                        chartColors.green,
                        chartColors.grey
                    ],
                }]
            },
            options: {
                responsive: true,
                legend: {
                    position: 'right'
                }
            }
        })
    }

    public static serverStatsSummary(server: ServerStats, container: JQuery): Chart
    {
        return new Chart(container, {
            type: 'line',
            data: {
                labels: server.getWeekLabelsArray(),
                datasets: [{
                    data: server.getWeekDataArray(),
                    backgroundColor: 'transparent'
                }]
            },
            options: this.globalOptions
        });
    }

}
