import * as $ from "jquery";

export class JSONRequest {

    static statusCodeHandler = (resolve) => {
        return {
            200: (response) => {
                return resolve(response);
            },
            204: () => {
                window.location.reload();
            },
            401: () => {
                window.location.href = '/login';
            },
        }
    };

    /**
     * ASYNC HTTP GET REQUEST
     *
     * @param url
     */
    public static get(url: string)
    {
        return new Promise((resolve, reject) => {
            $.get({
                url: url,
                dataType: 'json'
            }).done((response) => {
                return resolve(response);
            }).fail((response, code, error) => {
                return reject(Error('Laden fehlgeschlagen [ ' + error + ' ]'));
            });
        });
    }

    /**
     * ASYNC HTTP POST REQUEST
     *
     * @param url
     * @param data
     */
    public static post(url: string, data: object)
    {
        return new Promise((resolve, reject) => {
            $.post({
                url: url,
                data: data,
                dataType: 'json'
            }).done((response) => {
                return resolve(response);
            }).fail((response, code, error) => {
                if(response.responseJSON) {
                    return reject(Error(`<strong>${response.responseJSON.title}</strong>: ${response.responseJSON.message}.`));
                }
                return reject(Error(`Anfrage fehlgeschlagen [ ${error} ]`));
            });
        });
    }

    /**
     * ASYNC HTTP PUT REQUEST
     *
     * @param url
     * @param data
     * @param msgContainer
     * @param form
     */
    public static put(url: string, data: object, msgContainer: JQuery = null, form: JQuery = null)
    {
        if(msgContainer && form) {
            this.removeHighlight(msgContainer, form);
        }
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'put',
                url: url,
                data: data,
                dataType: 'json',
                statusCode: this.statusCodeHandler(resolve)
            }).fail((response) => {
                if(form !== null) {
                    this.highlightErrorFields(response, form);
                }
                return reject(Error(this.getErrorMessageFromJsonResponse(response)));
            });
        });
    }

    /**
     * ASYNC HTTP PATCH REQUEST
     *
     * @param url
     * @param data
     * @param msgContainer
     * @param form
     */
    public static patch(url: string, data: object, msgContainer: JQuery = null, form: JQuery = null)
    {
        if(msgContainer && form) {
            this.removeHighlight(msgContainer, form);
        }
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'patch',
                url: url,
                data: data,
                dataType: 'json',
                statusCode: this.statusCodeHandler(resolve)
            }).fail((response) => {
                if(form !== null) {
                    this.highlightErrorFields(response, form);
                }
                return reject(Error(this.getErrorMessageFromJsonResponse(response)));
            });
        })
    }

    /**
     * ASYNC HTTP DELETE REQUEST
     *
     * @param url
     */
    public static delete(url: string)
    {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'delete',
                url: url,
                dataType: 'json'
            }).done((response) => {
                return resolve(response);
            }).fail((response, code, error) => {
                return reject(Error('Löschung fehlgeschlagen [ ' + error + ' ]'));
            });
        });
    }

    private static getErrorMessageFromJsonResponse(response): string
    {
        let msg = response;
        if(response['responseJSON']['message'])
            msg = response['responseJSON']['message'];
        return msg;
    }

    private static removeHighlight(container: JQuery, form: JQuery)
    {
        container.empty();
        form.find('.input').each((index, element) => {
            console.log('removing highlight from ' + element.id);
            let e = $('#' + element.id);
            e.removeClass('is-danger is-link');
            e.parent().parent().find('p').remove();
        });
    }

    private static highlightErrorFields(response, form: JQuery)
    {
        let errors = response['responseJSON']['error'];
        Object.keys(errors).map((key) => {
            let input = form.find('#' + key);
            input.addClass('is-danger');
            input.parent().parent().find('.help').length === 0
                ? input.parent().parent()
                    .append('<p class="help is-danger">' + errors[key] + '</p>')
                : input.parent().parent().find('.help')
                    .replaceWith('<p class="help is-danger">' + errors[key] + '</p>')
        });
    }

}
